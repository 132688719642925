import * as React from 'react'
import * as styles from './css/faq.module.styl'
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql, Link } from "gatsby";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { widthInfo } from "../util/index.js"

const width = widthInfo()
const FAQList = [
  {
    title: "Who can get a loan on Barwaqt?",
    text: [
      "Citizen of Pakistan.",
      "18 years of age or older",
      "Person having their own Bank Account / Mobile Wallet.",
    ],
    isFolded: false
  },
  {
    title: "How to get loans on Barwaqt?",
    text: [
      "Download Barwaqt app from Google Play.",
      "Use the Phone Number that is registered with your CNIC.",
      "Fill in required Personal Information.",
      "The loan can be obtained after the Review is approved."
    ],
    isFolded: false
  },
  {
    title: "Why does Barwaqt collect your personal information?",
    text: [
      "To provide you better loan services and evaluate your Credit History."
    ],
    isFolded: false
  },
  {
    title: "Can I update my CNIC information in Barwaqt App?",
    text: [
      'Please ensure that your account is registered with your own CNIC. We only allow one account to be bound to the same CNIC. Once submitted, you can not modify the CNIC information. You are advised to fill your CNIC information carefully before clicking the "NEXT" button.'
    ],
    isFolded: false
  },
  {
    title: "Can I update my Bank Account information in Barwaqt App?",
    text: [
      "Once the Bank Account information is entered it cannot be updated. Please use the Account on which you want to recieve the loan."
    ],
    isFolded: false
  },
  {
    title: "How do I receive the loan?",
    text: [
      "You can use your Mobile Wallet OR Bank Account."
    ],
    isFolded: false
  },
  {
    title: "How to receive the loan through Bank Account?",
    text: [
      "By selecting your Bank from the list and entering your Account details."
    ],
    isFolded: false
  },
  {
    title: "How to receive the loan through Mobile Wallet?",
    text: [
      "You need to have a Mobile Wallet account on the same number as on Barwaqt. We will send the loan to your Mobile Wallet."
    ],
    isFolded: false
  },
  {
    title: "What is our review process?",
    text: [
      "We will check your Credit history based on the information provided, through Automated Risk Assesment Or you may recieve a call from the Verification Department."
    ],
    isFolded: false
  },
  {
    title: "Can I register on Barwaqt App through mobile number which is not registered on Mobile Wallet?",
    text: [
      "It is recommended to use the same mobile number to register the App that is on your Mobile Wallet account."
    ],
    isFolded: false
  },
  {
    title: "How to repay the loan?",
    text: [
      "There are three options to repay the loan:",

    ],
    listText: [
      ' Easypaisa Wallet.',
      ' 1Bill.',
      ' Easypaisa Token.',
    ],
    isFolded: false
  },
  {
    title: "How to repay the loan using Easypaisa?",
    text: [
      "There are three different methods to repay the loan using Easypaisa",
    ],
    listText: [
      ' Easypaisa App.',
      ' Easypaisa USSD.',
      ' Easypaisa Retailer.',
    ],
    isFolded: false
  },
  {
    title: "How to repay the loan through Mobile Wallet?",
    text: [
      "You need to have an Easypaisa App.",
      `Tap "Repayment Via Mobile Wallet" on Barwaqt App.`,
      'Tap "PAY NOW" button',
      "You will receive a prompt to enter the Easypaisa pin or login the Easypaisa App to confirm."
    ],
    isFolded: false
  },
  {
    title: "How to repay the loan through 1BILL?",
    text: [
      "You can either use Mobile Wallet or Internet Banking to repay the loan.",

    ],
    listText: [
      `Go to your Mobile Wallet or Internet Banking App / Website.`,
      'Type “1BILL” in the search bar and select 1Bill Invoice / Voucher from the search results.',
      "Enter your Barwaqt “Token Number” and proceed.",
      "Pay your loan.",

    ],
    isFolded: false
  },
  {
    title: "How much time do I have after the due date to return the loan?",
    text: [
      "With each passing day a late payment penalty will be charged. If you still don’t pay, legal action would be taken."
    ],
    isFolded: false
  },
  {
    title: "Is my information secure?",
    text: [
      "Yes, the information you provide is totally secure as we are a licensed company and need to abide by government laws to protect your information. The information is only used for verification purpose."
    ],
    isFolded: false
  },
  {
    title: "Why was my personal information collected?",
    text: [
      "We just ask for very limited information just to provide you with better loan services and evaluate your credit limit more effectively."
    ],
    isFolded: false
  },
  {
    title: "What is the exact interest based on the number of days?",
    text: [
      "The standard rate is 0.067%-0.56% per day but will be determined based on the credit rating."
    ],
    isFolded: false
  },
  {
    title: "On the App there is an option to choose amount from Rs.1,500 to Rs.25,000, But the approved amount is low. Why?",
    text: [
      "It is determined based upon your credit rating that how much loan would be approved. If you pay on time you can get a higher loan in future."
    ],
    isFolded: false
  },
  {
    title: "I forgot my Barwaqt App PIN, How can I sign in?",
    text: [
      "Dear Customer, Go to the Sign-In page of the Barwaqt App and use FORGOT PIN option to reset your PIN, Or tap on the OTP Verification to generate OTP."
    ],
    isFolded: false
  },
  {
    title: "How I can Keep my Account Secure?",
    text: [
      "Never disclose your OTP and password to anyone. Always use your personal mobile for logging into account."
    ],
    isFolded: false
  },
  {
    title: "What problems can you face if your Mobile Wallet account’s daily credit limit has been reached? What are the solutions to them?",
    text: [
      "Problem 1:",
      "The loan can’t be disbursed to your account.",
      "Solution:",
      "We recommend you get your wallet account biometrically verified. If your account is already verified, please wait for one day. We'll try to disburse again.",
      "Problem 2:",
      "You can’t repay your loan using your wallet account.",
      "Solution:",
      "1. Repay using any other Mobile Wallet account.",
      "2. Use partial repayment option to repay the amount.",
      "3. Repay in cash via Easypaisa shop.",
      "4. Get your account biometrically verified.",
    ],
    isFolded: false
  },
  {
    title: "I learned that our markup is from 0.067%-0.56%. How to get a lower markup?",
    text: [
      "Markup is comprehensively evaluated based on the information provided by each user. The better repayment record, by providing more accurate information, can lower the charges.Please keep your profile good by making timely repayments."
    ],
    isFolded: false
  },
  {
    title: "What is revolving loan service?",
    text: [
      "Revolving loan service offer the customers to borrow multiple loans within credit amount at a same time. Only eligible customers can borrow more than one loan amount at a same time. After unlocking the revolving loan service, eligible users can avail minimum 2 orders at same time and their is no limit for maximum orders."
    ],
    isFolded: false
  },
  {
    title: "How to unlock revolving loan service?",
    text: [
      "Maintain a good repayment history by timely repayments and become eligible user."
    ],
    isFolded: false
  },
  {
    title: "How to borrow money with revolving loan?",
    text: [
      "When a user clicks the [Get Loan] button on the home page to apply for an order, Revolving loan visible to only eligible users."
    ],
    isFolded: false
  },
  {
    title: "How to repay the revolving loan?",
    text: [
      "Each order is repaid normally according to the due date. If a user has multiple installment orders, there may be multiple due dates and amounts accordingly that need to be repaid on each due date."
    ],
    isFolded: false
  },
  {
    title: "What is Extended loan?",
    text: [
      "Loan Extension is a service that enables user able to get loan for extended period."
    ],
    isFolded: false
  },
  {
    title: "How many days can be extended?",
    text: [
      "Our customers can extend up to 60 days on top of their actual repay days"
    ],
    isFolded: false
  },
  {
    title: "When can I apply for loan extension?",
    text: [
      "A users can apply for extension loans if they meet the following two conditions:",
    ],
    listText: [
      '1. If you are eligible for deferment through credit assessment, you can choose it on the APP when applying for a loan.',
      '2. If you qualify for deferment through credit evaluation before the due date, you can choose the deferment service on the repayment page, and we will show you the new repayment plan.',
    ],
    isFolded: false
  },
  {
    title: "Why can't I apply for a deferment loan?",
    text: [
      "1. You may have an overdue order",
      "2. After evaluating the information you provided. We do not recommend using the extension service"
    ],
    isFolded: false
  },
  {
    title: "Would I be able to extend the loan after the due date?",
    text: [
      "No. A customer needs to apply extension service before due date and needs to pay 33% or 50% of the loan amount before/on due date to avail the extended loan service.",
    ],
    isFolded: false
  },
  {
    title: "Can I cancel extended loan?",
    text: [
      "Yes. An extended loan can be cancelled when 33% or 50% of the loan amount is not repaid before the due date.Customer can contact barwaqt customer service team to apply cancellation.",
    ],
    isFolded: false
  },
  {
    title: "Would there be any revision in loan agreement if I avail extended loan?",
    text: [
      "No, the loan agreement will not be revised. But when you apply for an extension, barwaqt will generate an extension agreement.",
    ],
    isFolded: false
  },
  {
    title: "What would be the markup rate on extended loan?",
    text: [
      "Markup rate will remain the same as per the actual loan.",
    ],
    isFolded: false
  },
  {
    title: "What is the loan amount that I can avail?",
    text: [
      "We offer loan amount from Rs. 600 to Rs. 25,000.",
    ],
    isFolded: false
  },
  {
    title: "How can I get No Objection Certificate (NOC)?",
    text: [
      "After paying back a loan, our customers can download NOC from our App by following the below steps:",
    ],
    listText: [
      'Login Barwaqt App',
      'My Account',
      'Order History',
      'Proof of Settlement',
    ],
    isFolded: false
  },
  {
    title: "How can I delete my Barwaqt account?",
    text: [
      "Users can delete their Barwaqt account through our App if there are no active/pending loan against their account. Following steps to be followed:",
    ],
    listText: [
      'Login Barwaqt App > My Account > Login Management > Account Deletion > Noted & Confirmed'
    ],
    isFolded: false
  },
]

const FAQ = () => {
  const { t } = useTranslation();
  let [typeList, handleTypeList] = React.useState(FAQList);
  let handleChangeList = (ind) => {
    typeList.map((item, index) => {
      item.isFolded = false;
      if (ind === index) {
        item.isFolded = true;
      }
      return item;
    });
    let datalistNew = typeList.slice();
    handleTypeList(datalistNew);
    return datalistNew
  };
  return (
    <Layout>
      <Seo title="FAQ" />
      <div className={styles.faq}>
        <div className={styles.faqBan}>
          <div className={styles.faqBanInner}>
            <div className={styles.faqBanText}>{t('banner.title')}</div>
            <div className={styles.faqBanPara}>{t('banner.subtitle')}</div>
            <a aria-label="faq download" className={styles.faqBanButton} href="https://play.google.com/store/apps/details?id=com.loan.cash.credit.barwaqt.tez.paisa.jazz.financial.ready.easy"></a>
            {
              width <= 1024 ? (
                <a aria-label="index download" className={styles.mobileSeedcredLink} target="_blank" href="https://www.seedcredfintec.com/"></a>
              ) : null
            }
          </div>

        </div>
        <div className={styles.faqList}>
          <h3 className={styles.faqTitle}>FAQs</h3>
          <ul className={styles.faqItems}>
            {FAQList.map((item, index) => (
              <div
                role="presentation"
                key={index}
                className={styles.faqItem}
                onClick={() => handleChangeList(index)}
              >
                <div className={styles.faqItemTitle}>
                  <span className={styles.faqItemTitleText}>
                    <span className={styles.faqItemTitleTextIndex}>   Q{index + 1}:</span>
                    <span className={styles.faqItemTitleTextContent}>    {item.title}</span>

                  </span>
                  <StaticImage
                    className={`${styles.faqItemIcon} ${item.isFolded === true ? 'block' : 'none'}`}
                    src="../images/faq/expand_circle_down_black_24dp_@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                  <StaticImage
                    className={`${styles.faqItemIcon} ${item.isFolded === true ? 'none' : 'block'}`}
                    src="../images/faq/expand_circle_down_black_24dp@2x.png"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </div>

                <ul className={`
                                    ${styles.faqItemContent} ${item.isFolded === true ? 'block' : 'none'}`}>
                  {item.text.map((itm, ind) => (
                    <li key={ind} className={styles.faqItemContentText}>
                      {index != 10 && index != 11 && index != 13 && index != 29 && index != 36 && index != 37 ? <div className={styles.faqItemLine}  > - </div> : <div className={styles.faqItemLine}  >   </div>}
                      <div className={styles.faqItemText}> {itm}  </div>
                    </li>

                  ))}
                  {item.listText && item.listText.map((itm, ind) => (
                    <li key={ind} className={styles.faqItemContentText}>
                      <div className={styles.faqItemLine}  >-</div>
                      <div className={styles.faqItemText}> {itm}  </div>

                    </li>

                  ))}
                  {(index === 11 || index === 13) ?
                    <span className={styles.faqItemContentText}>
                      <div className={styles.faqItemLine}  ></div>
                      <div className={styles.faqItemText}> For further details, please click the following link.  </div>
                    </span> : ""}

                </ul>
                <div className={styles.faqItemLink}>
                  {(index === 12 || index === 10 || index === 11 || index === 13) && item.isFolded === true ?
                    <div className={styles.faqItemLinkLi}>


                      {index === 11 ? <Link className={styles.faqItemLinkText} to='/RepaymentByEasypaisa'>Barwaqt Repayment Methods</Link> : ''}
                      {index === 13 || index === 10 ? <Link className={styles.faqItemLinkText} to='/oneBill'>Barwaqt Repayment Methods</Link> : ''}
                      {index === 12 ? <Link className={styles.faqItemLinkText} to='/repaymentViabyWa'>Barwaqt Repayment Methods</Link> : ''}
                      {width > 1024 ?
                        <StaticImage
                          className={styles.faqItemLinkIcon}
                          src="../images/faq/ic_keyboard_arrow_right_black_24px (1)@2x.png"
                          alt="A corgi smiling happily"
                          placeholder="blurred"
                        /> : null}
                    </div> : null

                  }
                  {(index === 1) && item.isFolded === true ?
                    <div className={styles.faqItemLinkLi}>
                      <Link to='/ApplicationProcess' className={styles.faqItemLinkText} >Application Process</Link>

                      {width > 1024 ?
                        <StaticImage
                          className={styles.faqItemLinkIcon}
                          src="../images/faq/ic_keyboard_arrow_right_black_24px (1)@2x.png"
                          alt="A corgi smiling happily"
                          placeholder="blurred"
                        /> : null}
                    </div> : null

                  }

                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default FAQ;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["faq", "header"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
